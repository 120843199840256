body {
  margin: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 16px;

  #c {
    max-width: calc(100vw - 32px);
    max-height: calc(var(--vh, 1vh) * 100 - 100px);
  }

  .info {
    width: calc(2880 / 1800 * (100vh - 100px));
    max-width: calc(100vw - 32px);
    font-family: monospace;
    display: flex;
    justify-content: space-between;
    font-size: 2vw;

    @media (min-width: 550px) {
      font-size: (2 * 550px / 100);
    }

    a {
      color: currentColor;
    }
  }
}
